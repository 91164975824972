// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-coverage-map-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/mobile-coverage-map.jsx" /* webpackChunkName: "component---src-pages-mobile-coverage-map-jsx" */),
  "component---src-pages-mobile-health-map-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/mobile-health-map.jsx" /* webpackChunkName: "component---src-pages-mobile-health-map-jsx" */),
  "component---src-pages-mobile-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/mobile.jsx" /* webpackChunkName: "component---src-pages-mobile-jsx" */),
  "component---src-pages-nbn-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/nbn.jsx" /* webpackChunkName: "component---src-pages-nbn-jsx" */),
  "component---src-pages-sign-up-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/sign-up.jsx" /* webpackChunkName: "component---src-pages-sign-up-jsx" */),
  "component---src-pages-sim-activation-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/sim-activation.jsx" /* webpackChunkName: "component---src-pages-sim-activation-jsx" */),
  "component---src-pages-sim-purchase-jsx": () => import("/solution/ANEX.Website.Osu/PB/src/pages/sim-purchase.jsx" /* webpackChunkName: "component---src-pages-sim-purchase-jsx" */)
}

